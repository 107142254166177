<template>
  <b-row>
    <b-col cols="12" v-if="can(this.$route.meta.permissionlink)">
      <b-card :title="this.$route.meta.title">
        <b-row class="pt-1 mb-1">
          <b-col md="8" lg="6" xl="4" class="ml-auto">
            <b-input-group size="8">
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="'Cari disini...'"
              />
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          ref="tableFe"
          striped
          hover
          responsive
          show-empty
          primary-key="email"
          class="position-relative d-none d-md-block"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :sort-by.sync="sortBy"
          :fields="fields"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(sppd_number)="data">
            <span v-b-popover.hover.bottom="data.item.sppd_number">
              {{ data.item.sppd_number.substr(0, 10) }}...
            </span>
          </template>

          <template #cell(name)="data">
            {{
              data.item.employee
                ? data.item.employee.employee + ' / ' + data.item.employee.name
                : '-'
            }}
          </template>

          <template #cell(destination_office)="data">
            {{ data.item.destination_office || '-' }}
          </template>

          <template #cell(departure_return_date)="data">
            {{ data.item.departure_date | moment('DD/MM/YYYY') }} -
            {{ data.item.return_date | moment('DD/MM/YYYY') }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div>
              <!-- <b-button
                v-if="
                  data.item.status_real_expense == 0 &&
                  can('approval_admin/pengeluaran-rill')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="showapproval1(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_real_expense == 1 &&
                  can('approval_pa/pengeluaran-rill')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="showapproval2(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button> -->
              <b-button
                v-if="can('detail/biaya-akomodasi')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Detail'"
                variant="info"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoDetailPage(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
        </b-table>

        <!-- Mobile Table -->
        <b-table
          ref="tableFeMobile"
          striped
          hover
          responsive
          show-empty
          class="position-static d-block d-md-none"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :sort-by.sync="sortBy"
          :fields="mobileFields"
        >
          <template #table-busy>
            <div class="text-center my-3">
              <div>Loading data, please wait...</div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(sppd_number)="data">
            <span v-b-popover.hover.bottom="data.item.sppd_number">
              {{ data.item.sppd_number.substr(0, 10) }}...
            </span>
          </template>

          <template #cell(name)="data">
            {{
              data.item.employee
                ? data.item.employee.employee + ' / ' + data.item.employee.name
                : '-'
            }}
          </template>

          <template #cell(Aksi)="data">
            <b-button
              v-if="can('detail/biaya-akomodasi')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-popover.hover.bottom="'Detail'"
              variant="info"
              size="sm"
              class="btn-icon float-sm-left mr-1"
              @click="gotoDetailPage(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="changePerPage()"
            />
          </b-form-group>

          <div>
            Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardBody,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBPopover,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import debounce from 'debounce'

import { getUserData } from '@/auth/utils'

export default {
  name: 'SppdList',
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/accomodations',
      },
      user: getUserData(),
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      from: 1,
      to: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      mobileFields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1' },
      ],
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'destination_office', label: 'Tujuan' },
        {
          key: 'departure_return_date',
          label: 'Tanggal Berangkat-Kembali',
          thStyle: 'width: 220px',
        },
        { key: 'sppd_type', label: 'Jenis Perjalanan Dinas' },
        { key: 'sppd_facility_type', label: 'Fasilitas' },
        { key: 'transportation_text', label: 'Alat Transportasi' },
        { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1' },
      ],
      items: [],
    }
  },
  watch: {
    // filter() {
    //   this.$refs.tableFe.refresh()
    //   this.$refs.tableFeMobile.refresh()
    // },
    search: debounce(function () {
      this.$refs.tableFe.refresh()
      this.$refs.tableFeMobile.refresh()
    }, 300),
  },
  methods: {
    gotoDetailPage(id) {
      const _ = this
      _.$router.push(`/biaya-akomodasi/${id}`)
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
      _.$refs.tableFeMobile.refresh()
    },
    getItems(ctx, callback) {
      const _ = this
      // let filter = ctx.filter ? '&key=' + ctx.filter : ''
      // const orderBy = ctx.sortDesc ? 'asc' : 'desc'
      // const spliting = ctx.sortBy.split('.')
      // let sortBy = spliting[spliting.length - 1]
      // if (sortBy.includes('_cast')) {
      //   sortBy = sortBy.split('_')[0]
      // }
      // sortBy = sortBy != '' ? sortBy : 'id'
      axios
        .get(
          `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}&search=${_.search}`,
        )
        .then(resp => {
          const consume = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          _.from = consume.from || 0
          _.to = consume.to || 0
          callback(consume.data || [])
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
      return null
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}
.btn-status {
  width: 30px;
  height: 30px;
}
</style>
